<template>
  <div class="d-flex align-items-center justify-content-center vh-100">
    <div class="text-center">
      <h1 class="display-1 fw-bold">404</h1>
      <p class="fs-3"> <span class="text-danger">Opps!</span> Nie znaleziono pokoju</p>
    </div>
  </div>
</template>

<script lang="ts" setup>

</script>